// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Features.css */

/* Breathing glow effect for the entire section */
.glow-animation {
    position: relative;
    animation: glow 3s ease-in-out infinite;
  }
  
  @keyframes glow {
    0%,
    100% {
      box-shadow: 0 0 20px rgba(173, 80, 23, 0.5);
    }
    50% {
      box-shadow: 0 0 40px rgba(173, 80, 23, 1);
    }
  }
  
  /* Flip card styles */
  .feature-card {
    perspective: 1000px;
  }
  
  .feature-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.7s;
    transform-style: preserve-3d;
  }
  
  .feature-card.rotate-y-180 .feature-card-inner {
    transform: rotateY(180deg);
  }
  
  .feature-card-front,
  .feature-card-back {
    position: absolute;
    width: 100%;
    backface-visibility: hidden;
  }
  
  .feature-card-back {
    transform: rotateY(180deg);
  }
  
  /* Rotation effect for Feature 3 */
  .rotate-360 {
    transform: rotate(360deg);
  }
  `, "",{"version":3,"sources":["webpack://./src/components/features.css"],"names":[],"mappings":"AAAA,iBAAiB;;AAEjB,iDAAiD;AACjD;IACI,kBAAkB;IAClB,uCAAuC;EACzC;;EAEA;IACE;;MAEE,2CAA2C;IAC7C;IACA;MACE,yCAAyC;IAC3C;EACF;;EAEA,qBAAqB;EACrB;IACE,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,0BAA0B;IAC1B,4BAA4B;EAC9B;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;;IAEE,kBAAkB;IAClB,WAAW;IACX,2BAA2B;EAC7B;;EAEA;IACE,0BAA0B;EAC5B;;EAEA,kCAAkC;EAClC;IACE,yBAAyB;EAC3B","sourcesContent":["/* Features.css */\n\n/* Breathing glow effect for the entire section */\n.glow-animation {\n    position: relative;\n    animation: glow 3s ease-in-out infinite;\n  }\n  \n  @keyframes glow {\n    0%,\n    100% {\n      box-shadow: 0 0 20px rgba(173, 80, 23, 0.5);\n    }\n    50% {\n      box-shadow: 0 0 40px rgba(173, 80, 23, 1);\n    }\n  }\n  \n  /* Flip card styles */\n  .feature-card {\n    perspective: 1000px;\n  }\n  \n  .feature-card-inner {\n    position: relative;\n    width: 100%;\n    height: 100%;\n    transition: transform 0.7s;\n    transform-style: preserve-3d;\n  }\n  \n  .feature-card.rotate-y-180 .feature-card-inner {\n    transform: rotateY(180deg);\n  }\n  \n  .feature-card-front,\n  .feature-card-back {\n    position: absolute;\n    width: 100%;\n    backface-visibility: hidden;\n  }\n  \n  .feature-card-back {\n    transform: rotateY(180deg);\n  }\n  \n  /* Rotation effect for Feature 3 */\n  .rotate-360 {\n    transform: rotate(360deg);\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
