// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/TT Supermolot Neue Trial ExtraBold Italic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/TT Supermolot Neue Trial Italic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/TT Supermolot Neue Trial Light Italic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/TT Supermolot Neue Trial Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'SupermolotExtraBoldItalic';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype'); /* Corrected path */
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'SupermolotItalic';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype'); /* Corrected path */
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'SupermolotLightItalic';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype'); /* Corrected path */
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'SupermolotRegular';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype'); /* Corrected path */
  font-weight: normal;
  font-style: normal;
}
`, "",{"version":3,"sources":["webpack://./src/assets/fonts.css"],"names":[],"mappings":"AAAA;EACE,wCAAwC;EACxC,+DAAoF,EAAE,mBAAmB;EACzG,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,+BAA+B;EAC/B,+DAA0E,EAAE,mBAAmB;EAC/F,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,oCAAoC;EACpC,+DAAgF,EAAE,mBAAmB;EACrG,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,gCAAgC;EAChC,+DAA2E,EAAE,mBAAmB;EAChG,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":["@font-face {\n  font-family: 'SupermolotExtraBoldItalic';\n  src: url('./fonts/TT Supermolot Neue Trial ExtraBold Italic.ttf') format('truetype'); /* Corrected path */\n  font-weight: 800;\n  font-style: italic;\n}\n\n@font-face {\n  font-family: 'SupermolotItalic';\n  src: url('./fonts/TT Supermolot Neue Trial Italic.ttf') format('truetype'); /* Corrected path */\n  font-weight: normal;\n  font-style: italic;\n}\n\n@font-face {\n  font-family: 'SupermolotLightItalic';\n  src: url('./fonts/TT Supermolot Neue Trial Light Italic.ttf') format('truetype'); /* Corrected path */\n  font-weight: 300;\n  font-style: italic;\n}\n\n@font-face {\n  font-family: 'SupermolotRegular';\n  src: url('./fonts/TT Supermolot Neue Trial Regular.ttf') format('truetype'); /* Corrected path */\n  font-weight: normal;\n  font-style: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
